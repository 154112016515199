import React from 'react'
import { graphql } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Center, Heading } from "@chakra-ui/react"

export function Head() {
  return (<>
    <title>JJR</title>
    <meta property="og:title" content="JJR | Contact" />
    <meta property="og:description" content="Jonathan Joseph Rosse Jewelry" />
    <meta property="og:image" content="/images/og_image.jpg" />
  </>)
}

const Page = ({ data }) => {

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImage, description } = node.data.target
        return (
          <GatsbyImage
            image={getImage(gatsbyImage)}
            alt={description}
          />
        )
      },
    },
  };

  return (
    <Center py="8" textAlign="center" flex="auto">
      <Heading>{data.contentfulPiece.name}</Heading>
    </Center>
  )
}

export default Page

export const data = graphql`
  query piecePageQuery($slug: String) {
    contentfulPiece(slug: { eq: $slug }) {
      name
    }
  }
`;